import { mapActions } from "vuex";

export default {
  methods: {
    async logOut() {
      await this.refresh();
      await this.$router.push('/login')
      await this.$auth.logout();

    },
    ...mapActions('utilisateur', ['getUsers', 'setFilter']),
    ...mapActions('settings', ['getSettings']),
    async refresh() {
      this.setFilter({ text: null })
      const users = await this.getUsers({ perPage: -1, page: -1 })
      localStorage.setItem("users", JSON.stringify(users))
      await this.getSettings();
    },
  }
}
