import Vue from 'vue';
const plugin = {
  install(Vue) {

    let EventBus = new Vue();

Vue.prototype.$EventBus = EventBus;

  }
}

Vue.use(plugin)

