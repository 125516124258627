import {
  ProjectStatus,
  ProjetClientApi, ProjetCommand,
  ReferentielClientApi,
  ReportingClientApi,
  StatusTask
} from "~/services/services";

export const state = () => ({
  _projectFilters: {
    status: undefined,
    clients: [],
    tags: [],
    isFacturable: undefined,
    hasTaskOpened: undefined,
    text: '',
    includeDeleted: false,
    orderBy: 'estimatedStartDate',
    orderAsc: false,
  },
  projects: [],
  project: {},
  page_title: "Projet",
  statusList: [],
  totalCount: 0
});

/*get State*/
export const getters = {
  projects: state => state.projects,
  totalCount: state => state.totalCount,
  statusList: state => state.statusList,
  _projectFilters: state => state._projectFilters,
  project: state => state.project,
  page_title: state => state.page_title,
}

/*Appel api etc...*/
export const actions = {
  /*Appels APi*/
  async getProjects({ commit, state }, params = {}) {
    const api = new ProjetClientApi(this);
    const res = await api.list(state._projectFilters.status, state._projectFilters.clients, state._projectFilters.tags, state._projectFilters.isFacturable,
      state._projectFilters.hasTaskOpened, params.start, params.end,
      state._projectFilters.text, state._projectFilters.includeDeleted, params.perPage,
      params.page, state._projectFilters.orderBy, state._projectFilters.orderAsc);
    commit('getProjects', res.data)
    commit('setCount', res.totalCount)

    return res.data;
  },

  async downloadProjects({ commit, state }, params = {}) {
    const api = new ProjetClientApi(this);
    return api.download(state._projectFilters.status, state._projectFilters.clients, state._projectFilters.tags, state._projectFilters.isFacturable,
      state._projectFilters.hasTaskOpened, params.start, params.end,
      state._projectFilters.text, state._projectFilters.includeDeleted);
  },
  async uploadProjects({ commit, state }, file) {
    const api = new ProjetClientApi(this);
    return api.uploadProjectExcelFile(file).then(async (response) => {
      this._vm.$successNotification('Message de validation', 'Vos données ont bien été importé')
    }).catch((error) => {
      this._vm.$errorNotification("Message d'erreur", 'Une erreur est survenue lors de l\'importation de vos données')
    });
  },

  async getOpenProject({ commit, state }, command) {
    const api = new ProjetClientApi(this);
    const res = await api.getProjetsForInput(command.userId, command.text);
    return res.data;
  },
  async projectTaskById({ commit, state }, command) {
    const api = new ProjetClientApi(this);
    return await api.tasks(command.id, StatusTask.OPENED, command.text, state._projectFilters.includeDeleted);
  },
  async getStatus({ commit, state }) {
    const api = new ReferentielClientApi(this);
    const res = await api.projectStatus()
    commit('getStatus', res)
    return res
  },

  async projectDocuments({ commit, state }, id) {
    const api = new ProjetClientApi(this);
    return [
      { url: 'https://kodeva.fr/document' },
      { url: 'https://kodeva.fr/document.pdf' },
      { url: 'https://kodeva.fr/document.xlsx' },
      { url: 'https://kodeva.fr/document.text' },
      { url: 'https://kodeva.fr/document.docx' },
    ]
    // return api.documents(id)
  },
  async deleteProject({ commit, state }, id) {
    const api = new ProjetClientApi(this);
    await api.delete(id).then((response) => {
      this._vm.$successNotification('Message de validation', 'Le projet a été supprimé avec succès')
    })
    commit('deleteProject', id);
  },
  async create({ commit, state }, body) {
    const api = new ProjetClientApi(this);
    await api.create(body).then(async (response) => {

      this._vm.$successNotification('Message de validation', 'Le projet a été crée avec succès')
    }).catch((error) => this._vm.$errorNotification("Message d'erreur", 'Une erreur est survenue lors de la création'))
  },
  async update({ commit, state }, params) {
    const api = new ProjetClientApi(this);

    await api.update(params.id, params.body).then(async (response) => {
      this._vm.$successNotification('Message de validation', 'Le projet a été modifié avec succès')
    }).catch((error) => {
      this._vm.$errorNotification("Message d'erreur", 'Une erreur est survenue lors de la modification')
    })
  },
  async addUpdateComment({ commit, state }, params) {
    const api = new ProjetClientApi(this);
    await api.addOrUpdateComment(params.id, params.body).then(async (response) => {
      this._vm.$successNotification('Message de validation', 'Le commentaire du projet a été mis à jour')
    }).catch((error) => {
      this._vm.$errorNotification("Message d'erreur", 'Une erreur est survenue')
    })
  },
  async getProject({ commit, state }, id) {
    const api = new ProjetClientApi(this);
    const res = await api.getById(id).catch(() => this._vm.$nuxt.error({
      statusCode: 404,
      message: "Désolé, le projet n'existe pas"
    }))
    commit('getProject', res)
    return res;
  },
  async updateProjectStatus({ commit, state }, params) {
    const api = new ProjetClientApi(this);
    const project = await api.getById(params.id).catch(() => this._vm.$nuxt.error({
      statusCode: 404,
      message: "Désolé, le projet n'existe pas"
    }))
    await api.update(params.id, new ProjetCommand({ ...project, projectStatus: params.key })).then(async (response) => {
      this._vm.$successNotification('Message de validation', 'Le projet a été modifié avec succès')
    }).catch((error) => {
      this._vm.$errorNotification("Message d'erreur", 'Une erreur est survenue lors de la modification')
    })
  },

  async downloadReportProject({ commit, state }, params) {
    const { reportCriteria } = params;
    const api = await new ReportingClientApi(this);
    return api.downloadReportProject("", reportCriteria);
  },
  setProject({ commit, state }, project) {
    commit('getProject', project)
    return project;
  },
  /*Méthodes*/
  setFilter({ commit, state }, filter) {
    commit('setFilters', filter)
    return filter;
  },
}


/*Set state*/
export const mutations = {
  setFilters(state, filter) {
    const key = Object.keys(filter);
    state._projectFilters[key] = filter[key]
  },
  getProjects(state, projects) {
    state.projects = projects
  },
  getProject(state, project) {
    state.page_title = "Projet " + (project?.externalId || "");
    state.project = project
  },
  getStatus(state, status) {
    const statusThemes = [
      {
        key: ProjectStatus.CREATE,
        color: 'danger',
        icon: 'stop'
      },
      {
        key: ProjectStatus.PROGRESS,
        color: 'success',
        icon: 'play_arrow'
      },
      {
        key: ProjectStatus.PAUSE,
        color: 'warning',
        icon: 'pause'
      },
      {
        key: ProjectStatus.CLOSE,
        color: 'dark',
        icon: 'sports_score'
      }
    ]
    statusThemes.map(s => {
      const current = status.find(d => d.key === s.key)
      current.color = s.color
      current.icon = s.icon
    })

    state.statusList = status
  },
  deleteProject(state, id) {
    state.projects = state.projects.filter(e => e.id !== id)
  },
  create(state, body) {
    state.projects.unshift(body)
  },
  setCount(state, count) {
    state.totalCount = count
  }


}
