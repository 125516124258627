import { ExportExterneClientApi } from "~/services/services";
import moment from "moment";

export const state = () => ({
  columns: [],
  dataExport: [],
  totalCount: 0,
  _weekFilter: moment().format("GGGG-[W]WW"),
  _sorting: {
    orderBy: "",
    orderAsc: true
  }
});

export const getters = {
  columns: state => state.columns,
  dataExport: state => state.dataExport,
  totalCount: state => state.totalCount,
  _weekFilter: state => state._weekFilter,
  _sorting: state => state._sorting
};

export const actions = {
  async getColumns({ commit, state }) {
    const api = new ExportExterneClientApi(this);
    const res = await api.getColumns();
    commit("setColumns", res.columns);
    return res.columns;
  },
  async getData({ commit, state }, params) {
    const api = new ExportExterneClientApi(this);
    // console.log("start date", params.startDate);
    // console.log("end date", params.endDate);
    const startDate = params.startDate;
    const endDate = params.endDate;

    await api
      .getData(
        startDate,
        endDate,
        params.perPage,
        params.page,
        state._sorting.orderBy,
        state._sorting.orderAsc
      )
      .then(value => {
        commit("setDataExport", value.rows);
        commit("setTotalCount", value.totalCount);
      });
  },
  async exportCSV({ commit, state }, params) {
    const api = new ExportExterneClientApi(this);
    await api
      .exportCSV(
        params.startDate,
        params.endDate,
        params.take,
        params.skip,
        state._sorting.orderBy,
        state._sorting.orderAsc
      )
      .then(response => {
        const file = new Blob([response.data], { type: "text/csv" });
        const fileURL = URL.createObjectURL(file);
        const fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", response.fileName);
        document.body.appendChild(fileLink);
        fileLink.click();
        return response;
      });
  },

  setWeekFilter({ commit, state }, filter) {
    const formattedFilter = moment(filter).format("GGGG-[W]WW");
    commit("setWeekFilter", formattedFilter);
    return filter;
  },
  setSorting({ commit, state }, filters) {
    commit("setSorting", filters);
    return filters;
  }
};

export const mutations = {
  setWeekFilter(state, filter) {
    state._weekFilter = filter;
  },
  setSorting(state, filters) {
    const key = Object.keys(filters);
    key == "orderAsc"
      ? (state._sorting.orderAsc = !filters.orderAsc)
      : (state._sorting[key] = filters[key]);
    // console.log(state._sorting.orderAsc);
  },
  setColumns(state, columns) {
    state.columns = columns;
  },
  setDataExport(state, data) {
    state.dataExport = data;
  },
  setTotalCount(state, total) {
    state.totalCount = total;
  }
};
