import {
  TimeClientApi,
  TimeSheetCriteria, UserIdValidationCommand,
  UserValidationTimeSheetCommand, ValidationByIdCommand,
  ValidationClientApi,
  ValidationListCommand, ValidTimeSheetCriteria
} from "~/services/services";
import moment from "moment";
export const state = () => ({
  _validationFilters: {
    selectedDates: {
      startDate: moment().startOf("week").toISOString(),
      endDate: moment().endOf("week").toISOString()
    },
    selectedEmployee: [],
    dropdownstatus: undefined,
  },
});

/*get State*/
export const getters = {
  _validationFilters: state => {
    return state._validationFilters;
  }
}

export const mutations = {
  setFilter(state, filter) {
    const key = Object.keys(filter);
    state._validationFilters[key] = filter[key]
  },
  setFilters(state, filters) {
    state._validationFilters = filters
  }
}



/*Appel api etc...*/
export const actions = {
  async getTimeSheetDetailById({ commit, state }, id) {

    const api = await new TimeClientApi(this);
    return await api.getTimeSheetById(id);

  },
  async getHistory({ commit, state }, id) {

    const api = await new ValidationClientApi(this);
    return await api.getValidationsHistory(id);

  },
  /*Appels APi*/
  async validateMyTimeSheet({ commit, state }, params = {}) {
    const { startDate, endDate } = params;
    const api = await new ValidationClientApi(this);
    return await api.validateMyTimeSheet(new UserValidationTimeSheetCommand.fromJS({ startDate, endDate })).then(r => {
      this._vm.$successNotification('Message de validation', 'Votre semaine a bien été validée')
    });
  },
  async askValidTimeSheet({ commit, state }, params = {}) {
    const { numSemaine, validationStatus, startDate, endDate, users, dropdownstatus } = params;
    const api = await new ValidationClientApi(this);
    return await api.askValidTimeSheet(new ValidationListCommand.fromJS({ numSemaine, validationStatus, startDate, endDate, users, dropdownstatus }));
  },
  async askTimeSheetById({ commit, state }, id) {
    const api = await new ValidationClientApi(this);
    return await api.askTimeSheet(id);
  },
  async updateTimeSheet({ commit, state }, params = {}) {
    const { validationStatus, userId, validationId, commentaire } = params;
    const api = await new ValidationClientApi(this);
    return await api.timeSheet(new ValidationByIdCommand.fromJS({ validationId, validationStatus, userId, commentaire }));
  }

}
