import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  Chip: () => import('../../components/Chip.vue' /* webpackChunkName: "components/chip" */).then(c => wrapFunctional(c.default || c)),
  ClientSideBar: () => import('../../components/ClientSideBar.vue' /* webpackChunkName: "components/client-side-bar" */).then(c => wrapFunctional(c.default || c)),
  HistoryCost: () => import('../../components/HistoryCost.vue' /* webpackChunkName: "components/history-cost" */).then(c => wrapFunctional(c.default || c)),
  KeyPin: () => import('../../components/KeyPin.vue' /* webpackChunkName: "components/key-pin" */).then(c => wrapFunctional(c.default || c)),
  Notification: () => import('../../components/Notification.vue' /* webpackChunkName: "components/notification" */).then(c => wrapFunctional(c.default || c)),
  NuxtLogo: () => import('../../components/NuxtLogo.vue' /* webpackChunkName: "components/nuxt-logo" */).then(c => wrapFunctional(c.default || c)),
  Pagination: () => import('../../components/Pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c)),
  ProjectNote: () => import('../../components/ProjectNote.vue' /* webpackChunkName: "components/project-note" */).then(c => wrapFunctional(c.default || c)),
  SearchModal: () => import('../../components/SearchModal.vue' /* webpackChunkName: "components/search-modal" */).then(c => wrapFunctional(c.default || c)),
  ShiftCard: () => import('../../components/ShiftCard.vue' /* webpackChunkName: "components/shift-card" */).then(c => wrapFunctional(c.default || c)),
  TaskDayHeader: () => import('../../components/TaskDayHeader.vue' /* webpackChunkName: "components/task-day-header" */).then(c => wrapFunctional(c.default || c)),
  Tutorial: () => import('../../components/Tutorial.vue' /* webpackChunkName: "components/tutorial" */).then(c => wrapFunctional(c.default || c)),
  Navbar: () => import('../../components/navbar.vue' /* webpackChunkName: "components/navbar" */).then(c => wrapFunctional(c.default || c)),
  Weekdayselector: () => import('../../components/weekdayselector.vue' /* webpackChunkName: "components/weekdayselector" */).then(c => wrapFunctional(c.default || c)),
  AbsenceSelect: () => import('../../components/AbsenceSteps/AbsenceSelect.vue' /* webpackChunkName: "components/absence-select" */).then(c => wrapFunctional(c.default || c)),
  BadgeColorBadge: () => import('../../components/Badge/ColorBadge.vue' /* webpackChunkName: "components/badge-color-badge" */).then(c => wrapFunctional(c.default || c)),
  BadgePrimeSamedi: () => import('../../components/Badge/PrimeSamedi.vue' /* webpackChunkName: "components/badge-prime-samedi" */).then(c => wrapFunctional(c.default || c)),
  BadgeStatusBadge: () => import('../../components/Badge/StatusBadge.vue' /* webpackChunkName: "components/badge-status-badge" */).then(c => wrapFunctional(c.default || c)),
  AuthLogo: () => import('../../components/Auth/AuthLogo.vue' /* webpackChunkName: "components/auth-logo" */).then(c => wrapFunctional(c.default || c)),
  AuthUserPinCard: () => import('../../components/Auth/UserPinCard.vue' /* webpackChunkName: "components/auth-user-pin-card" */).then(c => wrapFunctional(c.default || c)),
  AuthUserPinForm: () => import('../../components/Auth/UserPinForm.vue' /* webpackChunkName: "components/auth-user-pin-form" */).then(c => wrapFunctional(c.default || c)),
  AuthSelectUser: () => import('../../components/Auth/select-user.vue' /* webpackChunkName: "components/auth-select-user" */).then(c => wrapFunctional(c.default || c)),
  AuthUserPin: () => import('../../components/Auth/userPin.vue' /* webpackChunkName: "components/auth-user-pin" */).then(c => wrapFunctional(c.default || c)),
  ButtonsAddButton: () => import('../../components/Buttons/AddButton.vue' /* webpackChunkName: "components/buttons-add-button" */).then(c => wrapFunctional(c.default || c)),
  ButtonsContainedButton: () => import('../../components/Buttons/ContainedButton.vue' /* webpackChunkName: "components/buttons-contained-button" */).then(c => wrapFunctional(c.default || c)),
  ButtonsDisconnectButton: () => import('../../components/Buttons/DisconnectButton.vue' /* webpackChunkName: "components/buttons-disconnect-button" */).then(c => wrapFunctional(c.default || c)),
  ButtonsDropdown: () => import('../../components/Buttons/Dropdown.vue' /* webpackChunkName: "components/buttons-dropdown" */).then(c => wrapFunctional(c.default || c)),
  ButtonsExportButton: () => import('../../components/Buttons/ExportButton.vue' /* webpackChunkName: "components/buttons-export-button" */).then(c => wrapFunctional(c.default || c)),
  ButtonsImportButton: () => import('../../components/Buttons/ImportButton.vue' /* webpackChunkName: "components/buttons-import-button" */).then(c => wrapFunctional(c.default || c)),
  ButtonsPdfButton: () => import('../../components/Buttons/PdfButton.vue' /* webpackChunkName: "components/buttons-pdf-button" */).then(c => wrapFunctional(c.default || c)),
  ButtonsPrintButton: () => import('../../components/Buttons/printButton.vue' /* webpackChunkName: "components/buttons-print-button" */).then(c => wrapFunctional(c.default || c)),
  CardsDashboardListCard: () => import('../../components/Cards/DashboardListCard.vue' /* webpackChunkName: "components/cards-dashboard-list-card" */).then(c => wrapFunctional(c.default || c)),
  CardsDayOptionCard: () => import('../../components/Cards/DayOptionCard.vue' /* webpackChunkName: "components/cards-day-option-card" */).then(c => wrapFunctional(c.default || c)),
  CardsDaySheetCard: () => import('../../components/Cards/DaySheetCard.vue' /* webpackChunkName: "components/cards-day-sheet-card" */).then(c => wrapFunctional(c.default || c)),
  CardsTotalSheetCard: () => import('../../components/Cards/TotalSheetCard.vue' /* webpackChunkName: "components/cards-total-sheet-card" */).then(c => wrapFunctional(c.default || c)),
  DayItemsCommentBtn: () => import('../../components/DayItems/CommentBtn.vue' /* webpackChunkName: "components/day-items-comment-btn" */).then(c => wrapFunctional(c.default || c)),
  DayItemsModalComment: () => import('../../components/DayItems/ModalComment.vue' /* webpackChunkName: "components/day-items-modal-comment" */).then(c => wrapFunctional(c.default || c)),
  DeplacementStepsSwitchSelect: () => import('../../components/DeplacementSteps/SwitchSelect.vue' /* webpackChunkName: "components/deplacement-steps-switch-select" */).then(c => wrapFunctional(c.default || c)),
  FormsAbsenceForm: () => import('../../components/Forms/AbsenceForm.vue' /* webpackChunkName: "components/forms-absence-form" */).then(c => wrapFunctional(c.default || c)),
  FormsAddTaskModal: () => import('../../components/Forms/AddTaskModal.vue' /* webpackChunkName: "components/forms-add-task-modal" */).then(c => wrapFunctional(c.default || c)),
  FormsClientForm: () => import('../../components/Forms/ClientForm.vue' /* webpackChunkName: "components/forms-client-form" */).then(c => wrapFunctional(c.default || c)),
  FormsCostForm: () => import('../../components/Forms/CostForm.vue' /* webpackChunkName: "components/forms-cost-form" */).then(c => wrapFunctional(c.default || c)),
  FormsCostModal: () => import('../../components/Forms/CostModal.vue' /* webpackChunkName: "components/forms-cost-modal" */).then(c => wrapFunctional(c.default || c)),
  FormsDeplacementForm: () => import('../../components/Forms/DeplacementForm.vue' /* webpackChunkName: "components/forms-deplacement-form" */).then(c => wrapFunctional(c.default || c)),
  FormsProjectForm: () => import('../../components/Forms/ProjectForm.vue' /* webpackChunkName: "components/forms-project-form" */).then(c => wrapFunctional(c.default || c)),
  FormsTagsForm: () => import('../../components/Forms/TagsForm.vue' /* webpackChunkName: "components/forms-tags-form" */).then(c => wrapFunctional(c.default || c)),
  FormsTaskRefForm: () => import('../../components/Forms/TaskRefForm.vue' /* webpackChunkName: "components/forms-task-ref-form" */).then(c => wrapFunctional(c.default || c)),
  FormsTypesAbsencesForm: () => import('../../components/Forms/TypesAbsencesForm.vue' /* webpackChunkName: "components/forms-types-absences-form" */).then(c => wrapFunctional(c.default || c)),
  FormsUserForm: () => import('../../components/Forms/UserForm.vue' /* webpackChunkName: "components/forms-user-form" */).then(c => wrapFunctional(c.default || c)),
  FormsVisibilityModal: () => import('../../components/Forms/VisibilityModal.vue' /* webpackChunkName: "components/forms-visibility-modal" */).then(c => wrapFunctional(c.default || c)),
  IllustrationReportingIllustration: () => import('../../components/Illustration/ReportingIllustration.vue' /* webpackChunkName: "components/illustration-reporting-illustration" */).then(c => wrapFunctional(c.default || c)),
  IllustrationSettingIllustration: () => import('../../components/Illustration/SettingIllustration.vue' /* webpackChunkName: "components/illustration-setting-illustration" */).then(c => wrapFunctional(c.default || c)),
  InputsChipSelector: () => import('../../components/Inputs/ChipSelector.vue' /* webpackChunkName: "components/inputs-chip-selector" */).then(c => wrapFunctional(c.default || c)),
  InputsClientSuggest: () => import('../../components/Inputs/ClientSuggest.vue' /* webpackChunkName: "components/inputs-client-suggest" */).then(c => wrapFunctional(c.default || c)),
  InputsHourInput: () => import('../../components/Inputs/HourInput.vue' /* webpackChunkName: "components/inputs-hour-input" */).then(c => wrapFunctional(c.default || c)),
  InputsInputColor: () => import('../../components/Inputs/InputColor.vue' /* webpackChunkName: "components/inputs-input-color" */).then(c => wrapFunctional(c.default || c)),
  InputsInputPin: () => import('../../components/Inputs/InputPin.vue' /* webpackChunkName: "components/inputs-input-pin" */).then(c => wrapFunctional(c.default || c)),
  InputsInputSwitch: () => import('../../components/Inputs/InputSwitch.vue' /* webpackChunkName: "components/inputs-input-switch" */).then(c => wrapFunctional(c.default || c)),
  InputsSearchBar: () => import('../../components/Inputs/SearchBar.vue' /* webpackChunkName: "components/inputs-search-bar" */).then(c => wrapFunctional(c.default || c)),
  InputsTagsInput: () => import('../../components/Inputs/TagsInput.vue' /* webpackChunkName: "components/inputs-tags-input" */).then(c => wrapFunctional(c.default || c)),
  InputsTiersSelect: () => import('../../components/Inputs/TiersSelect.vue' /* webpackChunkName: "components/inputs-tiers-select" */).then(c => wrapFunctional(c.default || c)),
  InputsUserSelect: () => import('../../components/Inputs/UserSelect.vue' /* webpackChunkName: "components/inputs-user-select" */).then(c => wrapFunctional(c.default || c)),
  ListsDayAbsenceItem: () => import('../../components/Lists/DayAbsenceItem.vue' /* webpackChunkName: "components/lists-day-absence-item" */).then(c => wrapFunctional(c.default || c)),
  ListsDayAbsenceList: () => import('../../components/Lists/DayAbsenceList.vue' /* webpackChunkName: "components/lists-day-absence-list" */).then(c => wrapFunctional(c.default || c)),
  ListsDayTaskItem: () => import('../../components/Lists/DayTaskItem.vue' /* webpackChunkName: "components/lists-day-task-item" */).then(c => wrapFunctional(c.default || c)),
  ListsDayTaskList: () => import('../../components/Lists/DayTaskList.vue' /* webpackChunkName: "components/lists-day-task-list" */).then(c => wrapFunctional(c.default || c)),
  ListsTableList: () => import('../../components/Lists/TableList.vue' /* webpackChunkName: "components/lists-table-list" */).then(c => wrapFunctional(c.default || c)),
  ListsModalList: () => import('../../components/Lists/modalList.vue' /* webpackChunkName: "components/lists-modal-list" */).then(c => wrapFunctional(c.default || c)),
  NavigationDrawerNav: () => import('../../components/Navigation/DrawerNav.vue' /* webpackChunkName: "components/navigation-drawer-nav" */).then(c => wrapFunctional(c.default || c)),
  SvgConsultBackground: () => import('../../components/Svg/ConsultBackground.vue' /* webpackChunkName: "components/svg-consult-background" */).then(c => wrapFunctional(c.default || c)),
  TablesWeekSheetTable: () => import('../../components/Tables/WeekSheetTable.vue' /* webpackChunkName: "components/tables-week-sheet-table" */).then(c => wrapFunctional(c.default || c)),
  TimeComponentsDatePicker: () => import('../../components/TimeComponents/DatePicker.vue' /* webpackChunkName: "components/time-components-date-picker" */).then(c => wrapFunctional(c.default || c)),
  TimeComponentsInputDate: () => import('../../components/TimeComponents/InputDate.vue' /* webpackChunkName: "components/time-components-input-date" */).then(c => wrapFunctional(c.default || c)),
  TimeComponentsMonthPicker: () => import('../../components/TimeComponents/MonthPicker.vue' /* webpackChunkName: "components/time-components-month-picker" */).then(c => wrapFunctional(c.default || c)),
  TimeComponentsScrollTimerPicker: () => import('../../components/TimeComponents/ScrollTimerPicker.vue' /* webpackChunkName: "components/time-components-scroll-timer-picker" */).then(c => wrapFunctional(c.default || c)),
  TimeComponentsWeekButtons: () => import('../../components/TimeComponents/WeekButtons.vue' /* webpackChunkName: "components/time-components-week-buttons" */).then(c => wrapFunctional(c.default || c)),
  TimeComponentsWeekCalendar: () => import('../../components/TimeComponents/WeekCalendar.vue' /* webpackChunkName: "components/time-components-week-calendar" */).then(c => wrapFunctional(c.default || c)),
  TimeComponentsWeekTimeSheet: () => import('../../components/TimeComponents/WeekTimeSheet.vue' /* webpackChunkName: "components/time-components-week-time-sheet" */).then(c => wrapFunctional(c.default || c)),
  UserBadgeRole: () => import('../../components/User/BadgeRole.vue' /* webpackChunkName: "components/user-badge-role" */).then(c => wrapFunctional(c.default || c)),
  UserBannerAvatar: () => import('../../components/User/BannerAvatar.vue' /* webpackChunkName: "components/user-banner-avatar" */).then(c => wrapFunctional(c.default || c)),
  UserAvatar: () => import('../../components/User/UserAvatar.vue' /* webpackChunkName: "components/user-avatar" */).then(c => wrapFunctional(c.default || c)),
  UserNav: () => import('../../components/User/UserNav.vue' /* webpackChunkName: "components/user-nav" */).then(c => wrapFunctional(c.default || c)),
  ValidationDayItem: () => import('../../components/Validation/ValidationDayItem.vue' /* webpackChunkName: "components/validation-day-item" */).then(c => wrapFunctional(c.default || c)),
  ValidationHistory: () => import('../../components/Validation/ValidationHistory.vue' /* webpackChunkName: "components/validation-history" */).then(c => wrapFunctional(c.default || c)),
  ValidationWeekSummary: () => import('../../components/Validation/ValidationWeekSummary.vue' /* webpackChunkName: "components/validation-week-summary" */).then(c => wrapFunctional(c.default || c)),
  ValidationWeekSheetCol: () => import('../../components/Validation/WeekSheetCol.vue' /* webpackChunkName: "components/validation-week-sheet-col" */).then(c => wrapFunctional(c.default || c)),
  ViewDashboard: () => import('../../components/View/Dashboard.vue' /* webpackChunkName: "components/view-dashboard" */).then(c => wrapFunctional(c.default || c)),
  CommonFileIcon: () => import('../../components/common/FileIcon.vue' /* webpackChunkName: "components/common-file-icon" */).then(c => wrapFunctional(c.default || c)),
  CommonFilterNav: () => import('../../components/common/FilterNav.vue' /* webpackChunkName: "components/common-filter-nav" */).then(c => wrapFunctional(c.default || c)),
  CommonLoginSvg: () => import('../../components/common/LoginSvg.vue' /* webpackChunkName: "components/common-login-svg" */).then(c => wrapFunctional(c.default || c)),
  CommonLogoSvg: () => import('../../components/common/LogoSvg.vue' /* webpackChunkName: "components/common-logo-svg" */).then(c => wrapFunctional(c.default || c)),
  CommonModal: () => import('../../components/common/Modal.vue' /* webpackChunkName: "components/common-modal" */).then(c => wrapFunctional(c.default || c)),
  CommonNotFound: () => import('../../components/common/NotFound.vue' /* webpackChunkName: "components/common-not-found" */).then(c => wrapFunctional(c.default || c)),
  CommonStickyNav: () => import('../../components/common/StickyNav.vue' /* webpackChunkName: "components/common-sticky-nav" */).then(c => wrapFunctional(c.default || c)),
  CommonLoader: () => import('../../components/common/loader.vue' /* webpackChunkName: "components/common-loader" */).then(c => wrapFunctional(c.default || c)),
  ConsultationsConsultationHeader: () => import('../../components/consultations/ConsultationHeader.vue' /* webpackChunkName: "components/consultations-consultation-header" */).then(c => wrapFunctional(c.default || c)),
  ConsultationsUserProfile: () => import('../../components/consultations/UserProfile.vue' /* webpackChunkName: "components/consultations-user-profile" */).then(c => wrapFunctional(c.default || c)),
  DashboardInfosDay: () => import('../../components/dashboard/InfosDay.vue' /* webpackChunkName: "components/dashboard-infos-day" */).then(c => wrapFunctional(c.default || c)),
  DashboardRefuseTimeSheetCard: () => import('../../components/dashboard/RefuseTimeSheetCard.vue' /* webpackChunkName: "components/dashboard-refuse-time-sheet-card" */).then(c => wrapFunctional(c.default || c)),
  DashboardValidationTimeSheetCard: () => import('../../components/dashboard/ValidationTimeSheetCard.vue' /* webpackChunkName: "components/dashboard-validation-time-sheet-card" */).then(c => wrapFunctional(c.default || c)),
  DossiersDossier: () => import('../../components/dossiers/dossier.vue' /* webpackChunkName: "components/dossiers-dossier" */).then(c => wrapFunctional(c.default || c)),
  FiltresTagsFilter: () => import('../../components/filtres/TagsFilter.vue' /* webpackChunkName: "components/filtres-tags-filter" */).then(c => wrapFunctional(c.default || c)),
  FiltresBoolfilter: () => import('../../components/filtres/boolfilter.vue' /* webpackChunkName: "components/filtres-boolfilter" */).then(c => wrapFunctional(c.default || c)),
  FiltresClient: () => import('../../components/filtres/client.vue' /* webpackChunkName: "components/filtres-client" */).then(c => wrapFunctional(c.default || c)),
  FiltresDatepicker: () => import('../../components/filtres/datepicker.vue' /* webpackChunkName: "components/filtres-datepicker" */).then(c => wrapFunctional(c.default || c)),
  FiltresDaterange: () => import('../../components/filtres/daterange.vue' /* webpackChunkName: "components/filtres-daterange" */).then(c => wrapFunctional(c.default || c)),
  FiltresProject: () => import('../../components/filtres/project.vue' /* webpackChunkName: "components/filtres-project" */).then(c => wrapFunctional(c.default || c)),
  FiltresStatusproject: () => import('../../components/filtres/statusproject.vue' /* webpackChunkName: "components/filtres-statusproject" */).then(c => wrapFunctional(c.default || c)),
  FiltresUserFiltre: () => import('../../components/filtres/userFiltre.vue' /* webpackChunkName: "components/filtres-user-filtre" */).then(c => wrapFunctional(c.default || c)),
  IconsExcel: () => import('../../components/icons/Excel.vue' /* webpackChunkName: "components/icons-excel" */).then(c => wrapFunctional(c.default || c)),
  IconsHourlyRateIcon: () => import('../../components/icons/HourlyRateIcon.vue' /* webpackChunkName: "components/icons-hourly-rate-icon" */).then(c => wrapFunctional(c.default || c)),
  LoginCompanyChoice: () => import('../../components/login/CompanyChoice.vue' /* webpackChunkName: "components/login-company-choice" */).then(c => wrapFunctional(c.default || c)),
  LoginCompanyChoiceCard: () => import('../../components/login/CompanyChoiceCard.vue' /* webpackChunkName: "components/login-company-choice-card" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingCard: () => import('../../components/settings/SettingCard.vue' /* webpackChunkName: "components/settings-setting-card" */).then(c => wrapFunctional(c.default || c)),
  SettingsUtilisateurs: () => import('../../components/settings/utilisateurs.vue' /* webpackChunkName: "components/settings-utilisateurs" */).then(c => wrapFunctional(c.default || c)),
  DeplacementStepsMovingTimeDayPart: () => import('../../components/DeplacementSteps/MovingTime/DayPart.vue' /* webpackChunkName: "components/deplacement-steps-moving-time-day-part" */).then(c => wrapFunctional(c.default || c)),
  DeplacementStepsMovingTimeMoveTimeInput: () => import('../../components/DeplacementSteps/MovingTime/MoveTimeInput.vue' /* webpackChunkName: "components/deplacement-steps-moving-time-move-time-input" */).then(c => wrapFunctional(c.default || c)),
  DeplacementStepsMovingTimeTotalMoveTime: () => import('../../components/DeplacementSteps/MovingTime/TotalMoveTime.vue' /* webpackChunkName: "components/deplacement-steps-moving-time-total-move-time" */).then(c => wrapFunctional(c.default || c)),
  FormsTaskForm: () => import('../../components/Forms/TaskForm/index.vue' /* webpackChunkName: "components/forms-task-form" */).then(c => wrapFunctional(c.default || c)),
  TimeComponentsSelectTime: () => import('../../components/TimeComponents/SelectTime/SelectTime.vue' /* webpackChunkName: "components/time-components-select-time" */).then(c => wrapFunctional(c.default || c)),
  FormsTaskFormTaskStepsProjectSelect: () => import('../../components/Forms/TaskForm/TaskSteps/ProjectSelect.vue' /* webpackChunkName: "components/forms-task-form-task-steps-project-select" */).then(c => wrapFunctional(c.default || c)),
  FormsTaskSelect: () => import('../../components/Forms/TaskForm/TaskSteps/TaskSelect.vue' /* webpackChunkName: "components/forms-task-select" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
