//
//
//
//
//
//

import LogoSvg from "@/components/common/LogoSvg";

export default {
  name: "AuthLogo",
  components: {LogoSvg}
}
