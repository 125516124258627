import {ReferentielClientApi} from "~/services/services";

export const state = () => ({
  roles: [],
})


/*get State*/
export const getters = {
  roles: state => state.roles,
}


/*Appel api etc...*/
export const actions = {
  /*Appels APi*/
  async getRoles({commit, state}){
    const api = new ReferentielClientApi(this)
    const res = await api.roles();
    commit('setRoles', res)
    return res;
  }

  /*Methods*/
}

/*Set state*/
export const mutations = {
  setRoles(state, roles){
    state.roles = roles
  }
}
