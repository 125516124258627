//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    error: {type: Object, default: {statusCode: 500, message: 'Une erreur est survenue'}},
  },
  layout: 'error'
}
