//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ContainedButton from "~/components/Buttons/ContainedButton";

export default {
  name: "SelectTime",
  props: {
    showSwitch: {type: Boolean, default: true},
    currentTime: {type: Object, default: () => ({})},
    maxHours: {type: Number, default: 9},
    minutes: {type: Array, default: () => [0, 15, 30, 45]},
  },
  data() {
    return {
      selectedHour: this.verifyHours(),
      selectedMinute: this.verifyMinutes(),
      isFinish: this.currentTime.isFinish ? this.currentTime.isFinish: false,
    }
  },
  components: {
    ContainedButton
  },
  methods: {
    resetHour(){
      this.selectedHour = 0
    },

    updateMinute(min) {
      this.selectedMinute = min;
      this.$emit('selected', {inputTime: this.timeToFloat, isFinished: this.isFinish})
    },
    updateHour(hour) {
      const payload = parseInt(this.selectedHour + "" + hour);
    
      if(payload < 15 && payload >= 10){
        this.selectedHour = payload
      }else {
        this.selectedHour = hour;
      }

     this.$emit('selected', {inputTime: this.timeToFloat, isFinished: this.isFinish})
    },
    verifyHours(){
      const hours = this.currentTime.hours ? parseInt(this.currentTime.hours) : 0;
        return hours;
    },
    verifyMinutes(){
      const minutes = this.currentTime.minutes ? parseInt(this.currentTime.minutes) : 0;
      if (minutes && this.minutes.includes(minutes)){
        return minutes;
      }
      return 0;
    },
  },
  computed: {
    timeToFloat(){
      return this.selectedHour + this.selectedMinute / 60
    },
  },
  watch: {
    isFinish: function (newValue, oldValue) {
        this.$emit('selected', {inputTime: this.timeToFloat, isFinished: this.isFinish})
    },
  },
}
