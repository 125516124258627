//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import CompanyChoiceCard from './CompanyChoiceCard.vue';
import AuthLogo from "~/components/Auth/AuthLogo";
import { AccountClientApi } from "~/services/services";

export default {
    name: 'CompanyChoice',
    // layout: "anonymous",
    components: {
        CompanyChoiceCard,
    },
    props: {
        entreprises: { type: Array, default: [{}] },
        bodyBackground: { type: String, default: 'linear' },
        titleColor: { type: String, default: '#fff' },
        cardsWidth: { type: String, default: '60%' },
        cardsWidthSM: { type: String, default: '75%' },
    },
    data() {
        return {
            entSelected: this.$auth.user.entrepriseConnected,
            noChoice: false,
        }
    },

    methods: {
        handleSelectedEnt(selectedEnt) {
            this.entSelected = selectedEnt;
            this.noChoice = false;
        },
        async validSelectedEnt() {
            var client = new AccountClientApi(this.$store);
            client.setSelectedEntreprise(this.entSelected).then((response) => {
                this.$auth.strategy.token.set(response.token);
                this.$auth.fetchUser().catch(console.error);
                localStorage.setItem("lastuser", JSON.stringify(response.userId));
                this.$emit("connexionsuccess");
            })
        }
    },
    computed: {

        cssProps() {
            return {
                '--title-color': this.titleColor,
                '--cards-width': this.cardsWidth,
                '--cards-width-sm': this.cardsWidthSM,
            }
        }
    }
}

