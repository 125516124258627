import {TiersClientApi} from "~/services/services";

export const state = () => ({
  clients: [],
  client: {},
  _tierFilters: {
    text: "",
    includeDeleted: false,
    orderBy: "Name",
    orderAsc: true
  },
  totalCount: 0
})

/*get State*/
export const getters = {
  clients: state => state.clients,
  totalCount: state => state.totalCount,
  client: state => state.client,
  _tierFilters: state => state._tierFilters,
  tierCount: state => state.clients.length,
}


/*Appel api etc...*/
export const actions = {
  /*Appels APi*/
  async getClients({commit, state}, params = {}) {
    const api = new TiersClientApi(this);
    const tiers = await api.list(state._tierFilters.text, state._tierFilters.includeDeleted, params.perPage,
      params.page, state._tierFilters.orderBy, state._tierFilters.orderAsc)

    commit('setCount', tiers.totalCount)
    commit('setClients', tiers.data)
    return tiers.data;
  },
  async getClient({commit, state}, id) {
    const api = new TiersClientApi(this);
    const tier = await api.getById(id);
    commit('setClient', tier)
    return tier;
  },

  async downloadClients({commit, state}, params={}) {
    const api = new TiersClientApi(this);
    return api.download(state._tierFilters.text, state._tierFilters.includeDeleted);
  },
  async createClient({commit, state}, body) {
    const api = new TiersClientApi(this);
    await api.create(body).then(async (response) => {
      this._vm.$successNotification('Message de validation', `Le client ${body.name} a été crée avec succès`)
    }).catch((error) => this._vm.$errorNotification("Message d'erreur", 'Une erreur est survenue lors de la création'))
    //commit('createClient', body)
    return body;
  },
  async updateClient({commit, state}, params) {
    const api = new TiersClientApi(this);
    await api.update(params.id, params.body).then(async (response) => {
      this._vm.$successNotification('Message de validation', `Le client ${params.body.firstname} a été modifié avec succès`)
    }).catch((error) => this._vm.$errorNotification("Message d'erreur", 'Une erreur est survenue lors de la création'))
    //commit('updateClient', params.body)
    return params.body;
  },
  async deleteClient({commit, state}, id) {
    const api = new TiersClientApi(this);
    await api.delete(id).then((response) => {
      this._vm.$successNotification('Message de validation', 'Le client a été supprimé avec succès')
    })
  //  commit('deleteClient', id)
    return id;
  },

  /*Methods*/
  setFilter({commit, state}, filter) {
    commit('setFilters', filter)
    return filter;
  },
}


/*Set state*/
export const mutations = {
  setFilters(state, filter) {
    const key = Object.keys(filter);
    state._tierFilters[key] = filter[key]
  },
  setClients(state, clients) {
    state.clients = clients;
  },
  setClient(state, client) {
    state.client = client;
  },
  createClient(state, client) {
    state.clients.unshift(client)
  },
  updateClient(state, client) {
    //state.clients.push(client)
  },
  deleteClient(state, id) {
    state.clients = state.clients.filter(e => e.id !== id)
  },
  setCount(state, count) {
    state.totalCount = count
  }
}
