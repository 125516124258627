import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6adc8936 = () => interopDefault(import('../pages/chantiers/index.vue' /* webpackChunkName: "pages/chantiers/index" */))
const _0efa843f = () => interopDefault(import('../pages/clients/index.vue' /* webpackChunkName: "pages/clients/index" */))
const _7d3a5a6a = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _cf5a0140 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _c0b15194 = () => interopDefault(import('../pages/reports/index.vue' /* webpackChunkName: "pages/reports/index" */))
const _1e8339ae = () => interopDefault(import('../pages/reports/index/chantiers/index.vue' /* webpackChunkName: "pages/reports/index/chantiers/index" */))
const _e9fa2da8 = () => interopDefault(import('../pages/reports/index/taches/index.vue' /* webpackChunkName: "pages/reports/index/taches/index" */))
const _1c69e408 = () => interopDefault(import('../pages/reports/index/utilisateurs/index.vue' /* webpackChunkName: "pages/reports/index/utilisateurs/index" */))
const _978186d0 = () => interopDefault(import('../pages/reset-password/index.vue' /* webpackChunkName: "pages/reset-password/index" */))
const _7a817e10 = () => interopDefault(import('../pages/saisie.vue' /* webpackChunkName: "pages/saisie" */))
const _ed35943c = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _e133e954 = () => interopDefault(import('../pages/settings/index/absences/index.vue' /* webpackChunkName: "pages/settings/index/absences/index" */))
const _20f85f34 = () => interopDefault(import('../pages/settings/index/application/index.vue' /* webpackChunkName: "pages/settings/index/application/index" */))
const _042e50f4 = () => interopDefault(import('../pages/settings/index/export/index.vue' /* webpackChunkName: "pages/settings/index/export/index" */))
const _54db4586 = () => interopDefault(import('../pages/settings/index/taches.vue' /* webpackChunkName: "pages/settings/index/taches" */))
const _d5aa2cc8 = () => interopDefault(import('../pages/settings/index/taches-tags/index.vue' /* webpackChunkName: "pages/settings/index/taches-tags/index" */))
const _36d77c59 = () => interopDefault(import('../pages/settings/index/tags/index.vue' /* webpackChunkName: "pages/settings/index/tags/index" */))
const _b4df1060 = () => interopDefault(import('../pages/settings/index/utilisateurs/index.vue' /* webpackChunkName: "pages/settings/index/utilisateurs/index" */))
const _575fc5b8 = () => interopDefault(import('../pages/validation/index.vue' /* webpackChunkName: "pages/validation/index" */))
const _bd7bcdbe = () => interopDefault(import('../pages/chantiers/_id/index.vue' /* webpackChunkName: "pages/chantiers/_id/index" */))
const _edf4fa38 = () => interopDefault(import('../pages/chantiers/_id/index/index.vue' /* webpackChunkName: "pages/chantiers/_id/index/index" */))
const _0c3b7cca = () => interopDefault(import('../pages/chantiers/_id/index/documents.vue' /* webpackChunkName: "pages/chantiers/_id/index/documents" */))
const _0c0a9224 = () => interopDefault(import('../pages/create-password/_id/index.vue' /* webpackChunkName: "pages/create-password/_id/index" */))
const _283673e5 = () => interopDefault(import('../pages/utilisateur/_id/index.vue' /* webpackChunkName: "pages/utilisateur/_id/index" */))
const _2e2176ba = () => interopDefault(import('../pages/validation/_id/index.vue' /* webpackChunkName: "pages/validation/_id/index" */))
const _482400f4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/chantiers",
    component: _6adc8936,
    name: "chantiers"
  }, {
    path: "/clients",
    component: _0efa843f,
    name: "clients"
  }, {
    path: "/dashboard",
    component: _7d3a5a6a,
    name: "dashboard"
  }, {
    path: "/login",
    component: _cf5a0140,
    name: "login"
  }, {
    path: "/reports",
    component: _c0b15194,
    name: "reports",
    children: [{
      path: "chantiers",
      component: _1e8339ae,
      name: "reports-index-chantiers"
    }, {
      path: "taches",
      component: _e9fa2da8,
      name: "reports-index-taches"
    }, {
      path: "utilisateurs",
      component: _1c69e408,
      name: "reports-index-utilisateurs"
    }]
  }, {
    path: "/reset-password",
    component: _978186d0,
    name: "reset-password"
  }, {
    path: "/saisie",
    component: _7a817e10,
    name: "saisie"
  }, {
    path: "/settings",
    component: _ed35943c,
    name: "settings",
    children: [{
      path: "absences",
      component: _e133e954,
      name: "settings-index-absences"
    }, {
      path: "application",
      component: _20f85f34,
      name: "settings-index-application"
    }, {
      path: "export",
      component: _042e50f4,
      name: "settings-index-export"
    }, {
      path: "taches",
      component: _54db4586,
      name: "settings-index-taches"
    }, {
      path: "taches-tags",
      component: _d5aa2cc8,
      name: "settings-index-taches-tags"
    }, {
      path: "tags",
      component: _36d77c59,
      name: "settings-index-tags"
    }, {
      path: "utilisateurs",
      component: _b4df1060,
      name: "settings-index-utilisateurs"
    }]
  }, {
    path: "/validation",
    component: _575fc5b8,
    name: "validation"
  }, {
    path: "/chantiers/:id",
    component: _bd7bcdbe,
    children: [{
      path: "",
      component: _edf4fa38,
      name: "chantiers-id-index"
    }, {
      path: "documents",
      component: _0c3b7cca,
      name: "chantiers-id-index-documents"
    }]
  }, {
    path: "/create-password/:id",
    component: _0c0a9224,
    name: "create-password-id"
  }, {
    path: "/utilisateur/:id",
    component: _283673e5,
    name: "utilisateur-id"
  }, {
    path: "/validation/:id",
    component: _2e2176ba,
    name: "validation-id"
  }, {
    path: "/",
    component: _482400f4,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
