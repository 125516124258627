import Vue from 'vue'
import moment from 'moment-timezone';

moment.locale("fr");

Vue.filter('dayThreeLetters', function (value) {
  if (value) {
    return moment(value).format('ddd')
  }
});
Vue.filter('dayLittleMonth', function (value) {
  if (value) {
    return moment(value).format('MMM')
  }
});

Vue.filter('dateToDayName', function (value) {
  if (value) {
    return moment(value).format('dddd')
  }
});



Vue.filter('dateToWeekNum', function (value) {
  if (value) {
    const val = moment(value).format("MM-DD-YYYY")
    return "S" + moment(val, "MM-DD-YYYY").week();
  }
});
Vue.filter('weekNum', function (value) {
  if (value) {
    return moment(value, "MM-DD-YYYY").week();
  }
  return null
});
Vue.filter('monthYear', function (value) {
  if (value) {
    return moment(value).format('MMMM YYYY')
  }
});
Vue.filter('floatToHour', function (number) {
  if (number || number === 0) {
    let sign = (number >= 0) ? 1 : -1;
    number = number * sign;
    const hour = Math.floor(number);
    let decpart = number - hour;
    const min = 1 / 60;
    decpart = min * Math.round(decpart / min);
    let minute = Math.floor(decpart * 60) + '';
    if (minute.length < 2) {
      minute = '0' + minute;
    }
    sign = sign === 1 ? '' : '-';
    return sign + hour + 'h' + minute;
  }
  return '0h00'
});
Vue.filter('remainingDays', function (value) {
  if (value === "default") {
    return null
  }
  if (value) {
    const res = moment(value).diff(moment(), 'days')
    if (res >= 0)
      return res + 1
    return '-'
  }
  return '-'
});

Vue.filter('pastDays', function (value) {
  const today = moment();
  const date = moment(value);
  if (value) {
    const res = today.diff(date, 'days')
    if (res > 0 || date.format('L') === today.format('L'))
      return res + 1
    return '-'
  }
  return '-'
});

Vue.filter('dayTwoNumber', function (value) {
  if (value) {
    return moment(value).format('D')
  }
});

Vue.filter('dateToString', function (value) {
  if (value) {
    return moment(value).format('Do MMMM YYYY')
  }
});
Vue.filter('dateSlash', function (value) {
  if (value) {
    return moment(value).format('DD/MM/YYYY')
  }
});
Vue.filter('dateYearTwoDigit', function (value) {
  if (value) {
    return moment(value).format('DD/MM/YY')
  }
  return 'N/A'
});

Vue.filter('dateToClock', function (value) {
  if (value) {
    return moment(value).format('HH:mm:ss')
  }
});
Vue.filter('dateToHour', function (value) {
  if (value) {
    return moment(value).format('HH') + "h" + moment(value).format('mm')
  }
});


const DatePlugin = {

  install(Vue, options) {
    Vue.prototype.$toFr = (Date) => {
      var sDay = moment(Date).format("YYYY-MM-DD");
      var day = moment.tz(sDay, 'Europe/Paris');

      return day;
    }
  }
}
Vue.use(DatePlugin)

