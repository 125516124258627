/*Appel api etc...*/
import {CoutHoraireClientApi} from "~/services/services";

export const actions = {
  /*Appels APi*/
  async getHistoryCost({commit, state}, id) {
    const api = new CoutHoraireClientApi(this)
    return await api.getByUserId(id)
  },
  async getCostByID({commit, state}, coutId) {
    const api = new CoutHoraireClientApi(this)
    return await api.getById(coutId)
  },
  async createCost({commit, state}, body) {
    const api = new CoutHoraireClientApi(this)
    return await api.create(body).then((response) => {
      this._vm.$successNotification('Message de validation', `Le taux horaire a été ajouté avec succès`)
    }).catch((error) => {
      const errorMessage = JSON.parse(error.response).code;
      const message = errorMessage ? errorMessage : 'Une erreur est survenue lors de la création';
      this._vm.$errorNotification("Message d'erreur", message)
    })
  },
  async updateCost({commit, state}, params) {
    const api = new CoutHoraireClientApi(this);
    await api.update(params.id, params.body).then(async (response) => {
      this._vm.$successNotification('Message de validation', `Le taux horaire a été modifié avec succès`)
    }).catch((error) => {
      const errorMessage = JSON.parse(error.response).code;
      const message = errorMessage ? errorMessage : 'Une erreur est survenue lors de la modification';
      this._vm.$errorNotification("Message d'erreur", message)
    })

  },
  async deleteCost({commit, state}, id) {
    const api = new CoutHoraireClientApi(this);
    await api.delete(id).then((response) => {
      this._vm.$successNotification('Message de validation', `Le taux horaire a été supprimé avec succès`)
    })
  },
}
