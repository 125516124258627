//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Modal",
  props: {
    value: { type: Boolean, default: false },
    action: { type: String, default: "alert" },
    okTitle: { type: String, default: "Ok" },
    smallOnMobile: { type: Boolean, default: false },
    cancelTitle: { type: String, default: "Annuler" },
    commentairePlaceHolder: { type: String, default: "" },
    commentaire: { type: String, default: null },
    disableCommentaire: { type: Boolean, default: false },
    variant: { type: String, default: "primary" },
    hideClickBackdrop: { type: Boolean, default: true },
    title: { type: String, default: "" },
    bodyclass: { type: String, default: "" },
    description: { type: String, default: "" },
    data: { type: Array, default: () => [] },
    disableOk: { type: Boolean, default: false },
    hideFooter: { type: Boolean, default: false }
  },
  watch: {
    value(val) {
      setTimeout(() => {
        if (val && this.commentaireValue) {
          this.$refs.commentaire?.focus();
        }
      }, 300);
    }
  },
  computed: {
    open: {
      get: function() {
        return this.value;
      },
      set: function(value) {
        this.$emit("input", value);
      }
    },
    commentaireValue: {
      get: function() {
        return this.commentaire;
      },
      set: function(value) {
        this.$emit("updateCommentaire", value);
      }
    }
  },
  methods: {
    onHide(evt) {
      if (evt.trigger === "backdrop" && this.hideClickBackdrop) {
        evt.preventDefault();
        evt.target.classList.add("prevent-modal");
        setTimeout(() => {
          evt.target.classList.remove("prevent-modal");
        }, 300);
      }
    },
    handleOk() {
      this.$emit("handleOk");
    }
  }
};
