export const state = () => ({
  app: {},
})



/*get State*/
export const getters = {
  app: state => state.app,
  hoursDelay: state => state.app.hoursDelay ? state.app.hoursDelay : null,
  alerteHoursDayMax: state => state.app.alerteHoursDayMax ? state.app.alerteHoursDayMax : null,
  alerteHoursWeekMax: state => state.app.alerteHoursWeekMax ? state.app.alerteHoursWeekMax : null,
  isManageTempsTrajet: state => state.app.isManageTempsTrajet ? state.app.isManageTempsTrajet : false,
  isManagePanierRepas: state => state.app.isManagePanierRepas ? state.app.isManagePanierRepas : false,
  isManageGrandDeplacement: state => state.app.isManageGrandDeplacement ? state.app.isManageGrandDeplacement : false,
  manageValidation: state => state.app.manageValidation ? state.app.manageValidation : false,
  isManageAbsences: state => state.app.isManageAbsences ? state.app.isManageAbsences : false,
  isManageResponsable: state => state.app.isManageResponsable ? state.app.isManageResponsable : false,
  isManageCommercial: state => state.app.isManageCommercial ? state.app.isManageCommercial : false,
  isManageCost: state => state.app.isManageCost ? state.app.isManageCost : false,
  isManageKmsPro: state => state.app.isManageKmsPro ? state.app.isManageKmsPro : false,
  isManageKmsPerso: state => state.app.isManageKmsPerso ? state.app.isManageKmsPerso : false,
  displayWeekEnd: state => state.app.displayWeekEnd ? state.app.displayWeekEnd : false,
  isManageKms: state => state.app.isManageKmsPerso || state.app.isManageKmsPro,
  isEditTimeByAdmin: state => state.app.isEditTimeByAdmin ? state.app.isEditTimeByAdmin : false,
  isManageNightHours: state => state.app.isManageNightHours ?? false,
  isManageExportErp: state => state.app.isManageExportErp ??false,
  isTempsTrajetSurHeureTravaillees: state => state.app.isTempsTrajetSurHeureTravaillees ? state.app.isTempsTrajetSurHeureTravaillees : false,
  primeSamedi: state => state.app.primeSamedi ? state.app.primeSamedi : null,
}


/*Appel api etc...*/
export const actions = {
  /*Methods*/
  initStorageApp({ commit }) {

    if (process && !process.server && localStorage) {

      const app = JSON.parse(localStorage.getItem('settings')) || {};
      console.log(app)
      if (app) {
        commit('setApp', app.app)
        return app.app;
      }
    }
    commit('setApp', {})
    return {}
  }
}

/*Set state*/
export const mutations = {
  setApp(state, obj) {
    state.app = obj;

  },
}
