import {ProjetClientApi, StatusTask, TagCommand, TagsClientApi} from "~/services/services";

export const state = () => ({
  _tagsFilters: {
    text: "",
    includeDeleted: false,
    orderBy: "CreatedOn",
    orderAsc: false,
  },
  refTags: []
})

/*get State*/
export const getters = {
  _tagsFilters: state => state._tagsFilters
}


/*Appel api etc...*/
export const actions = {
  /*Appels APi*/
  async getTags({commit, state}, params={}) {
    const api = new TagsClientApi(this);
    var result =  await api.getTags(state._tagsFilters.text, state._tagsFilters.includeDeleted, params.perPage,
      params.page, state._tagsFilters.text.orderBy, state._tagsFilters.text.orderAsc)
      commit('setTags', result.data)
      return result;
  },
  async createTag({commit, state}, body) {
    const api = new TagsClientApi(this)
    return await api.createTag(new TagCommand(body)).then((response) => {
      this._vm.$successNotification('Message de validation', `Le mot-clé a été créé avec succès`)
    }).catch((error) => {
      const errorMessage = JSON.parse(error.response).code;
      const message = errorMessage ? errorMessage : 'Une erreur est survenue lors de la création';
      this._vm.$errorNotification("Message d'erreur", message)
    })
  },
  async updateTag({commit, state}, params = {}) {
    const api = new TagsClientApi(this);
    await api.updateTag(params.id, new TagCommand(params.body)).then(async (response) => {
      this._vm.$successNotification('Message de validation', `Le mot-clé a été modifié avec succès`)
    }).catch((error) => {
      const errorMessage = JSON.parse(error.response).code;
      const message = errorMessage ? errorMessage : 'Une erreur est survenue lors de la modification';
      this._vm.$errorNotification("Message d'erreur", message)
    })

  },
  async deleteTag({commit, state}, id) {
    const api = new TagsClientApi(this);
    await api.deleteTag(id).then((response) => {
      this._vm.$successNotification('Message de validation', `Le mot-clé a été supprimé avec succès`)
    })
  },
  /*Methods*/
  setFilter({commit, state}, filter) {
    commit('setFilters', filter)
    return filter;
  },
}

/*Set state*/
export const mutations = {
  setFilters(state, filter) {
    const key = Object.keys(filter);
    state._tagsFilters[key] = filter[key]
  },
  setTags(state, tags) {
    state.refTags= tags;
  
  }
}
