//
//
//
//
//
//
//
//
//
//

export default {
  name: "LogoSvg",
  props: {
    height: {type: Number, default: 35},
    width: {type: Number, default: 35}
  }
}
