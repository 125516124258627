import Vue from 'vue'


Vue.filter('numToEuro', function (number) {
  if (number) {
    return new Intl.NumberFormat('fr-Fr', { style: 'currency', currency: 'EUR' }).format(number)
  }
  return 'N/A'
});
Vue.filter('fullName', function (obj) {
  if (obj) {
    return obj.prenom + " " + obj.nom;
  }
  return 'N/A'
});

Vue.filter('boolToString', function (value) {
  if (value) return 'Oui'
  return 'Non'
});

Vue.filter('toKm', function (value) {
  if (value || value === 0){
    return value + ' Km'
  }
  return 'N/A'
});

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his childrens
      if (!(el === event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
});
