//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'loader',
  props: {
    fullScreen: {type: Boolean, default: false}
  }
};
