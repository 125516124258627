//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import route from "~/mixins/route";
import roles from "~/mixins/roles";
import LogoSvg from "@/components/common/LogoSvg";
import { mapGetters, mapActions } from "vuex";
import auth from "@/mixins/auth";

import Modal from "@/components/common/Modal";
import CompanyChoice from "../login/CompanyChoice.vue";

export default {
  name: "DrawerNav",
  components: { LogoSvg, Modal, CompanyChoice },
  mixins: [auth,roles],
  props: {
    value: { type: Boolean, default: false }
  },
  methods: {
    ...mapActions("settings", ["getSettings"]),
    isActiveLink(to) {
      const currentTo = to.split("/")[1];
      return currentTo === this.currentRouteName;
    },
    isExactLink(to) {
      return to === this.$route.path;
    },
    handleOver(link) {
      if (!link.subMenu) {
        this.show = true;
        this.showSubMenu = false;
        return;
      }

      if (this.showSubMenu && this.currentMenu !== link.subMenu) {
        this.showSubMenu = false;
        return;
      }
      this.currentMenu = link.subMenu;
      let start = 0;
      const showingDelay = 300;
      const self = this;
      this.hoverInterval = setInterval(function() {
        start += 100;
        if (start === showingDelay) {
          self.showSubMenu = true;
          clearInterval(this.hoverInterval);
        }
      }, 100);
    },
    handleClearInterval() {
      clearInterval(this.hoverInterval);
    },
    handleOut() {
      this.handleClearInterval();
      if (this.showSubMenu) {
        this.currentMenu = [];
        this.showSubMenu = false;
      }
    },

    handleToggle() {
      this.showSubMenu = false;
      this.$emit("input", !this.value);
    },
    handleClickSwitchEnt() {
      this.allClose();
      this.switch_ent.open = true;
    },
    async handleSwitchEnt() {
      window.location.href = "/";
    },
    handleClickLogout() {
      this.allClose();
      this.confirm_disconnect.title = "Voulez-vous vraiment vous déconnecter ?";
      this.confirm_disconnect.open = true;
    },
    async handleLogout() {
      await this.logOut();
    },
    allClose() {
      clearInterval(this.hoverInterval);
      this.$emit("input", false);
      this.show = false;
      this.showSubMenu = false;
    }
  },

  computed: {
    ...mapGetters("localStorage", ["manageValidation", "isManageResponsable","isManageExportErp"]),
    ...mapGetters("settings", ["settings"]),

    switchOpenEnt() {
      return this.switch_ent.open || !this.currentEntreprise;
    },
    entreprises() {
      if (!this.$auth.user) return [];
      return this.$auth.user.entreprises;
    },
    currentEntreprise() {
      return this.entreprises.find(
        d => d.id == this.$auth.user.entrepriseConnected
      );
    },
    authorized_links() {
      if (!this.manageValidation) {
        return this.links?.filter(
          d =>
            d.to !== "/validation" &&
            (d.roles.length === 0 || this.isInRole(d.roles))
        );
      }
      return this.links?.filter(
        d => d.roles.length === 0 || this.isInRole(d.roles)
      );
    },
    menuStyle() {
      if (!this.showSubMenu) {
        return `left: -500px`;
      }
      if (this.value) {
        return `left: ${this.menuWidthOpen}px`;
      } else {
        return `left: ${this.menuWidthClose}px`;
      }
    }
  },
  data() {
    return {
      show: false,
      showSubMenu: false,
      hoverInterval: null,
      currentMenu: [],
      menuWidthOpen: 300,
      confirm_disconnect: {
        title: "",
        open: false
      },
      menuWidthClose: 80,
      switch_ent: {
        icon: "swap_horiz",
        title: "Changement d'entreprise",
        roles: [],
        open: false
      },
      disconnect_link: {
        icon: "power_settings_new",
        title: "Déconnexion",
        to: "/login",
        roles: []
      },
      links: [
        { icon: "home", title: "Accueil", to: "/", roles: [] },
        {
          icon: "edit_calendar",
          title: "Saisie Jour/Semaine",
          to: "/saisie",
          roles: []
        },
        {
          icon: "assignment",
          title: "Chantiers",
          to: "/chantiers",
          roles: ["administrateur", "superadmin"]
        },
        {
          icon: "people_alt",
          title: "Clients",
          to: "/clients",
          roles: ["administrateur", "superadmin"]
        },
        {
          icon: "show_chart",
          title: "Rapports",
          to: "/reports/chantiers",
          roles: ["administrateur", "superadmin"],
          subMenu: [
            {
              title: "Chantiers",
              links: [
                {
                  icon: "assignment",
                  title: "Rapport chantier",
                  to: "/reports/chantiers",
                  roles: ["administrateur", "superadmin"]
                }
              ]
            },
            {
              title: "Tâches",
              links: [
                {
                  icon: "task",
                  title: "Rapport tâches",
                  to: "/reports/taches",
                  roles: ["administrateur", "superadmin"]
                }
              ]
            },
            {
              title: "Utilisateurs",
              links: [
                {
                  icon: "people_alt",
                  title: "Rapports utilisateurs",
                  to: "/reports/utilisateurs",
                  roles: ["administrateur", "superadmin"]
                }
              ]
            }
          ]
        },
        {
          icon: "assignment_turned_in",
          title: "Validation temps",
          to: "/validation",
          roles: []
        },
        {
          icon: "account_circle",
          title: "Mon compte",
          to: "/utilisateur/" + this.$auth.user?.id?.toString(),
          roles: ["utilisateur", "responsable"]
        },
        {
          icon: "settings",
          title: "Paramètres",
          to: "/settings/utilisateurs",
          roles: ["administrateur", "superadmin"],
          subMenu: [
            {
              title: "Paramètres",
              links: [
                {
                  icon: "groups",
                  title: "Utilisateurs",
                  to: "/settings/utilisateurs",
                  roles: ["administrateur", "superadmin"]
                },
                {
                  icon: "task",
                  title: "Tâches",
                  to: "/settings/taches",
                  roles: ["administrateur", "superadmin"]
                },
                {
                  icon: "local_offer",
                  title: "Mots clés",
                  to: "/settings/tags",
                  roles: ["administrateur", "superadmin"]
                },
                {
                  icon: "person_off",
                  title: "Types Absences",
                  to: "/settings/absences",
                  roles: ["administrateur", "superadmin"]
                },
                {
                  icon: "file_upload",
                  title: "Export",
                  to: "/settings/export",
                  roles: ["administrateur", "superadmin"]
                },
                {
                  icon: "apps",
                  title: "Application",
                  to: "/settings/application",
                  roles: ["administrateur", "superadmin"]
                }
              ]
            }
          ]
        }
      ]
    };
  },
  mixins: [route, roles, auth]
};
