import Vue from "vue";
import * as Sentry from "@sentry/browser";
import { ReportingObserver as ReportingObserverIntegration, CaptureConsole } from "@sentry/integrations";
const plugin = {
    install(Vue) {
        Sentry.init({
            release: "www-time-20270727",
            dsn: "https://06a6d3cbe4da4590b4bfbed2105d10fe@glitchtip.kodeva.fr/4",
            integrations: [new Sentry.BrowserTracing(), new ReportingObserverIntegration(), new CaptureConsole({ levels: ['error'] })],
            tracesSampleRate: 1.0
        });


    }
}
Vue.use(plugin)