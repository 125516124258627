import { UtilisateurClientApi } from "~/services/services";

export const state = () => ({
  users: [],
  user: {},
  _userFilters: {
    role: null,
    minLevelRole: null,
    text: "",
    includeDeleted: false,
    orderBy: "CreatedOn",
    orderAsc: true,
    totalCount: 0,
  },
})

/*get State*/
export const getters = {
  users: state => state.users,
  totalCount: state => state.totalCount,
  user: state => state.user,
  _userFilters: state => state._userFilters,
  userCount: state => state.users.length,
}


/*Appel api etc...*/
export const actions = {
  /*Appels APi*/
  async getUsers({ commit, state }, params = {}) {

    const includeDeleted = params.includeDeleted ? params.includeDeleted : state._userFilters.includeDeleted;
    const api = new UtilisateurClientApi(this);
    const res = await api.list(state._userFilters.role?.label, state._userFilters.minLevelRole, state._userFilters.text, includeDeleted,
      params.perPage, params.page, state._userFilters.orderBy, state._userFilters.orderAsc);
    commit('setCount', res.totalCount)
    commit('setUsers', res.data)
    return res;
  },
  async getUser({ commit, state }, id) {
    const api = new UtilisateurClientApi(this);
    return await api.getById(id).catch(() => {
      this._vm.$nuxt.error({
        statusCode: 404,
        message: "Désolé, l'utilisateur n'existe pas"
      })
    });
  },

  async downloadUsers({ commit, state }, params = {}) {
    const role = state._userFilters.role?.label;
    const api = new UtilisateurClientApi(this);
    return api.download(role, null, state._userFilters.text, state._userFilters.includeDeleted);
  },
  async createUser({ commit, state }, body) {
    const api = new UtilisateurClientApi(this);
    await api.create(body).then((response) => {
      const route = `/utilisateur/${response.id}`
      $nuxt.$router.push(route)
      this._vm.$successNotification('Message de validation', `L'utilisateur ${body.prenom} a été crée avec succès. Un mail lui a été envoyé.`)
    }).catch((error) => {
      const message = error.response && typeof error.response === "string" ? JSON.parse(error.response) : 'Une erreur est survenue lors de la création'
      this._vm.$errorNotification("Message d'erreur", message)
    })
  },
  async deleteUser({ commit, state }, id) {
    const api = new UtilisateurClientApi(this);
    await api.delete(id, this.$auth.user.id.toString()).then((response) => {
      this._vm.$successNotification('Message de validation', `L'utilisateur a été supprimé avec succès`)
    })
    commit('deleteUser', id);
  },
  async activeUser({ commit, state }, id) {
    const api = new UtilisateurClientApi(this);
    await api.active(id, this.$auth.user.id.toString()).then((response) => {
      this._vm.$successNotification('Message de validation', `L'utilisateur a été réactivé avec succès`)
    })
    commit('deleteUser', id);
  },
  async updateUser({ commit, state }, params) {
    const api = new UtilisateurClientApi(this);
    await api.update(params.id, params.body).then(async (response) => {
      this._vm.$successNotification('Message de validation', `L'utilisateur ${params.body.prenom} a été modifié avec succès`)
    }).catch((error) => {
      const errorMessage = JSON.parse(error.response).code;
      const message = errorMessage ? errorMessage : 'Une erreur est survenue lors de la modification';
      this._vm.$errorNotification("Message d'erreur", message)

    })

  },

  /*Methods*/
  setFilter({ commit, state }, filter) {
    commit('setFilters', filter)
    return filter;
  },
}

/*Set state*/
export const mutations = {
  setFilters(state, filter) {
    const key = Object.keys(filter);
    state._userFilters[key] = filter[key]
  },
  deleteUser(state, id) {
    state.users = state.users.filter(e => e.id !== id)
  },
  setUsers(state, users) {
    state.users = users;
  },
  setCount(state, count) {
    state.totalCount = count
  }
}
