/*
* Gestion des catégories liées aux utilisateurs / tâches  permettant un filtre lors de la sélection
*/
import { TagCategoryCommanDto, TagsCategoryClientApi } from "~/services/services";

export const state = () => ({
  _tagsFilters: {
    text: "",
    includeDeleted: false
  },
  refTags: []
})

/*get State*/
export const getters = {
  _tagsFilters: state => state._tagsFilters
}

export const actions = {
  /*
  * Appels APi
  */

  async getTagsCategory({ commit, state }, params = {}) {
    const api = new TagsCategoryClientApi(this);
    var result = await api.getTags(state._tagsFilters.text, state._tagsFilters.includeDeleted)
    commit('setTags', result.map(d => { return { "key": d.id, "label": d.categoryName } }))
    return result;
  },

  setFilter({ commit, state }, filter) {
    commit('setFilters', filter)
    return filter;
  },
}

/*Set state*/
export const mutations = {
  setFilters(state, filter) {
    const key = Object.keys(filter);
    state._tagsFilters[key] = filter[key]
  },
  setTags(state, tags) {
    state.refTags = tags;

  }
}
