import {ProjetClientApi, ReferentielClientApi, ReportingClientApi, TaskClientApi} from "~/services/services";

export const state = () => ({
  tasks: [],
  taskStatus: [],
  projectTasks: [],
  _taskFilters: {
    statusTask: undefined,
    text: undefined,
    includeDeleted: false,
    orderBy: 'label',
    orderAsc: false,
  },
  taskBody: {
    TaskProjectLabel: "",
    description: "",
    statusTask: "",
    timePlanned: null,
    taskRefId: ""
  },
  totalCount: 0
})

/*get State*/
export const getters = {
  taskBody: state => state.taskBody,
  projectTasks: state => state.projectTasks,
  _taskFilters: state => state._taskFilters,
  taskStatus: state => state.taskStatus,
  tasks: state => state.tasks,
  totalCount: state => state.totalCount,
}


/*Appel api etc...*/
export const actions = {
  /*API*/
  /*project tasks*/
  async createProjectTask({commit, state}, params) {
    const api = new ProjetClientApi(this);
    return await api.addTask(params.id, params.body).then(async (response) => {
      this._vm.$successNotification('Message de validation', 'La tache a été créée avec succès');
      return response;
    }).catch((error) => this._vm.$errorNotification("Message d'erreur", 'Une erreur est survenue lors de la création'))
    
  },
  async updateProjectTask({commit, state}, params) {
    const api = new ProjetClientApi(this);
    await api.addOrUpdateTask(params.id, params.body.id, params.body).then(async (response) => {
      this._vm.$successNotification('Message de validation', 'La tache a été modifiée avec succès')
    }).catch((error) => this._vm.$errorNotification("Message d'erreur", 'Une erreur est survenue lors de la modification'))
  },
  async getTasks({commit, state}, params = {}) {
    const api = new TaskClientApi(this);
    const res = await api.getTasks(state._taskFilters.text, state._taskFilters.includeDeleted, params.perPage,
      params.page, state._taskFilters.orderBy, state._taskFilters.orderAsc);
    commit('setTasks', res.data)
    commit('setCount', res.totalCount)
    return res.data;
  },
  async getTaskById({commit, state}, id) {
    const api = new ProjetClientApi(this);
    const res = await api.tasks(id, state._taskFilters.statusTask, state._taskFilters.text, state._taskFilters.includeDeleted);
    commit('setTask', res)
    return res
  },
  async getTaskStatus({commit, state}) {
    const api = new ReferentielClientApi(this);
    const res = await api.taskStatus();
    commit('setStatus', res)
    return res
  },

  async deleteTask({commit, state}, id) {
    const api = new TaskClientApi(this);
    await api.deleteTask(id).then((response) => {
      this._vm.$successNotification('Message de validation', 'La tâche a été supprimée avec succès')
    }).catch(error => {
      this._vm.$errorNotification('Message d\'erreur', JSON.parse(error.response))
      return error.response;
    })
  },
  async deleteProjectTask({commit, state}, id) {
    const api = new ProjetClientApi(this);
    await api.deleteTask(id).then((response) => {
      this._vm.$successNotification('Message de validation', 'La tâche a été supprimée avec succès')
    }).catch(error => {
      this._vm.$errorNotification('Message d\'erreur', JSON.parse(JSON.stringify(error.response)))
      return error.response;
    })
  },

  async downloadReportTasks({commit, state}, params){
    const {reportCriteria} = params;
    const api = await new ReportingClientApi(this);
    return api.downloadReportTask("", reportCriteria);
  },

  /*Methods*/
  setBody({commit, state}, item) {
    commit('setBody', item)
    return item;
  },
  setNewBody({commit, state}, body) {
    commit('setNewBody', body)
    return body;
  },
  resetTaskBody({commit, state}) {
    const body = {
      TaskProjectLabel: "",
      description: "",
      statusTask: "",
      timePlanned: null,
      taskRefId: ""
    }
    commit('setNewBody', body)
    return body;
  },
  setFilter({commit, state}, filter) {
    commit('setFilters', filter)
    return filter;
  },
}


/*Set state*/
export const mutations = {
  setFilters(state, filter) {
    const key = Object.keys(filter);
    state._taskFilters[key] = filter[key]
  },
  setBody(state, item) {
    const key = Object.keys(item);
    state.taskBody[key] = item[key]
  },
  setNewBody(state, body) {
    state.taskBody = body;
  },
  setStatus(state, status) {
    state.taskStatus = status;
  },
  setTasks(state, tasks) {
    state.tasks = tasks;
  },
  setTask(state, task) {
    state.task = task;
  },
  setCount(state, count) {
    state.totalCount = count
  }
}
