import { TypeAbsenceClientApi, TypeAbsenceCommand } from "~/services/services";

export const state = () => ({
  _absenceFilters: {
    text: "",
    includeDeleted: false,
    orderBy: "CreatedOn",
    orderAsc: false,
  }
})

/*get State*/
export const getters = {
  _absenceFilters: state => state._absenceFilters
}

/*Appel api etc...*/
export const actions = {
  /*Appels APi*/
  async getTypesAbsences({ commit, state }, params = {}) {
    const api = new TypeAbsenceClientApi(this);
    return await api.getTypeAbsences(state._absenceFilters.text, state._absenceFilters.includeDeleted, params.perPage,
      params.page, state._absenceFilters.text.orderBy, state._absenceFilters.text.orderAsc)
  },
  async createTypeAbsence({ commit, state }, body) {
    const api = new TypeAbsenceClientApi(this)
    //label?: string | undefined;
    return await api.creerTypeAbsence(new TypeAbsenceCommand(body)).then((response) => {
      this._vm.$successNotification('Message de validation', `Un nouveau type d'absence a été ajouté`)
    }).catch((error) => {
      const errorMessage = JSON.parse(error.response).code;
      const message = errorMessage ? errorMessage : 'Une erreur est survenue lors de la création';
      this._vm.$errorNotification("Message d'erreur", message)
    })
  },
  async updateTypeAbsence({ commit, state }, params = {}) {
    const api = new TypeAbsenceClientApi(this);
    //label?: string | undefined;
    await api.modifierTypeAbsence(params.id, new TypeAbsenceCommand(params.body)).then(async (response) => {
      this._vm.$successNotification('Message de validation', `Le type d'absence a été modifié avec succès`)
    }).catch((error) => {
      const errorMessage = JSON.parse(error.response).code;
      const message = errorMessage ? errorMessage : 'Une erreur est survenue lors de la modification';
      this._vm.$errorNotification("Message d'erreur", message)
    })

  },
  async deleteTypeAbsence({ commit, state }, id) {
    const api = new TypeAbsenceClientApi(this);
    await api.supprimerAbsence(id).then((response) => {
      this._vm.$successNotification('Message de validation', `Le type d'absence a été supprimé avec succès`)
    })
  },
  /*Methods*/
  setFilter({ commit, state }, filter) {
    commit('setFilters', filter)
    return filter;
  },
}
/*Set state*/
export const mutations = {
  setFilters(state, filter) {
    const key = Object.keys(filter);
    state._absenceFilters[key] = filter[key]
  }
}
