import { SettingsClientApi } from "~/services/services";

export const state = () => ({
  settings: {},
  settingsOptions: []
})


/*get State*/
export const getters = {
  settings: state => state.settings,
  settingsOptions: state => state.settingsOptions,
}


/*Appel api etc...*/
export const actions = {
  /*Appels APi*/
  async getSettings({ commit, state }) {
    const api = new SettingsClientApi(this);
    const res = await api.settings()

    commit('setSettings', res)
    return res;
  },

  async getOptions({ commit, state }) {
    const api = new SettingsClientApi(this);
    const res = await api.featuresOptions()
    commit('setSettingsOptions', res)
    return res;
  },
  async updateSettings({ commit, state }, body) {
    const api = new SettingsClientApi(this);
    await api.updateSettings(body).then((res) => {
      this._vm.$successNotification('Message de validation', `Les paramètres ont été modifié avec succès`)
    }).catch(err => {
      this._vm.$errorNotification("Message d'erreur", 'Une erreur est survenue lors de la modification')
    });
  },
  /*Methods*/
  setSetting({ commit, state }, obj) {
    commit('setSetting', obj)
    return obj;
  }
}

/*Set state*/
export const mutations = {
  setSettings(state, body) {
    state.settings = body;
    localStorage.setItem('settings', JSON.stringify({ app: body }))
  },
  setSetting(state, obj) {
    const { key, value } = obj;
    state.settings[key] = value
  },
  setSettingsOptions(state, options) {
    state.settingsOptions = options
  },
}
