import {
  ReportingClientApi,
  TemplateClientApi,
  TimeClientApi,
  TimeSheetCriteria,
  InputTimeCommentCommand
} from "~/services/services";

export const state = () => ({
  _timesheetFilters: {
    userIds: []
  },
  timesheet: [],
  currentUser: undefined
});

export const actions = {
  async getTimeSheets({ commit, state }, command) {
    const api = new TimeClientApi(this);

    return await api.getTimeSheets(
      new TimeSheetCriteria.fromJS({
        startDate: command.startDate,
        endDate: command.endDate,
        userIds: command.userIds
      })
    );
  },
  async getTemplate({ commit, state }, params = {}) {
    const api = new TemplateClientApi(this);
    const response = await api.getTimeSheets(
      null,
      1,
      new TimeSheetCriteria.fromJS(params)
    );
    const file = new Blob([response.data], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    const fileLink = document.createElement("a");
    fileLink.href = fileURL;
    fileLink.setAttribute("download", response.fileName);
    document.body.appendChild(fileLink);
    fileLink.click();
    return response;
  },
  async addTime({ commit, state }, body) {
    const api = new TimeClientApi(this);
    await api.addInputTime(body).then(response => {
      this._vm.$successNotification(
        "Message de validation",
        "Votre temps a bien été ajouté"
      );
    });
  },
  async addAbsence({ commit, state }, body) {
    const api = new TimeClientApi(this);
    await api.addAbsenceTime(body).then(response => {
      this._vm.$successNotification(
        "Message de validation",
        "Votre absence a bien été ajoutée"
      );
    });
  },
  async reportUser({ commit, state }, params = {}) {
    const { startDate, endDate, userIds } = params;
    const api = await new ReportingClientApi(this);
    return await api.getReportsUser(startDate, endDate, userIds);
  },

  async downloadReportUser({ commit, state }, params = {}) {
    const { startDate, endDate, userIds } = params;
    const api = await new ReportingClientApi(this);
    return api.downloadReportUser(startDate, endDate, userIds);
  },
  async updateTime({ commit, state }, params) {
    const api = new TimeClientApi(this);
    await api.update(params.id, params.body).then(response => {
      this._vm.$successNotification(
        "Message de validation",
        "Votre temps a bien été modifié"
      );
    });
  },
  async updateAbsence({ commit, state }, params) {
    const api = new TimeClientApi(this);
    await api.updateAbsence(params.id, params.body).then(response => {
      this._vm.$successNotification(
        "Message de validation",
        "Votre absence a bien été modifiée"
      );
    });
  },
  async addTimeOptions({ commit, state }, body) {
    const api = new TimeClientApi(this);
    await api.addOrUpdateTimeSheet(body).then(response => {
      this._vm.$successNotification(
        "Message de validation",
        "Votre déplacement a bien été ajouté"
      );
    });
  },
  async getTasks({ commit, state }, command) {
    const api = new TimeClientApi(this);
    return await api.getTasks(
      new TimeSheetCriteria.fromJS({
        startDate: command.startDate,
        endDate: command.endDate,
        userIds: command.userIds
      })
    );
  },
  async getAbsences({ commit, state }, command) {
    const api = new TimeClientApi(this);
    return await api.getAbsences(
      new TimeSheetCriteria.fromJS({
        startDate: command.startDate,
        endDate: command.endDate,
        userIds: command.userIds
      })
    );
  },
  async deleteSheetTask({ commit, state }, params) {
    const api = new TimeClientApi(this);
    await api.delete(params.id).then(response => {
      this._vm.$successNotification(
        "Message de validation",
        "Votre saisie a bien été supprimée"
      );
    });
  },
  async deleteSheetAbsence({ commit, state }, params) {
    const api = new TimeClientApi(this);
    await api.deleteAbsence(params.id).then(response => {
      this._vm.$successNotification(
        "Message de validation",
        "Votre absence a bien été supprimée"
      );
    });
  },
  async deleteSheetInfos({ commit, state }, params) {
    const api = new TimeClientApi(this);
    await api
      .addOrUpdateTimeSheet({
        userId: params.userId,
        inputDate: params.inputDate,
        panierRepas: undefined,
        grandDeplacement: undefined,
        tempsTrajet: undefined,
        kmPro: undefined,
        kmPerso: undefined
      })
      .then(response => {
        this._vm.$successNotification(
          "Message de validation",
          "Vos informations ont bien été supprimées"
        );
      });
  },
  async updateTaskComment({ commit, state }, params) {
    const api = new TimeClientApi(this);
    const commentCommand = new InputTimeCommentCommand.fromJS({
      comment: params.comment
    });
    console.log(params);
    console.log(commentCommand);
    await api.updateInputTimeComment(params.id, commentCommand).then(
      () => {
        this._vm.$successNotification(
          "Message de validation",
          "Commentaire mis à jour"
        );
      },
      () => {
        this._vm.$errorNotification(
          "Message d'erreur",
          "Une erreur s'est produite"
        );
      }
    );
  }
};

export const mutations = {
  setCurrentUser(state, user) {
    const key = Object.keys(user);
    state.currentUser = {};
    key.forEach(s => {
      state.currentUser[s] = user[s];
    });
  }
};
