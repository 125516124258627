//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
    name: "CompanyChoiceCard",
    props: {
        companyLogo: { type: String, default: require('@/assets/images/logo.png') },
        companyName: { type: String, default: '' },
    },
    methods: {
        emitEvent(id) {
            this.$emit('entClicked');
        }
    },
}

