import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'

export default ({ app, store }, inject) => {
    const hub = new HubConnectionBuilder()

        .withUrl('/ws/GestionTempsHub', {

        })
        .configureLogging(LogLevel.Error)
        .withAutomaticReconnect([0, 2000, 10000, 30000, null])
        .build()

    inject('hub', hub)

    hub.on('Connected', message => {
        console.info('Connected to SignalR Hub.', message)
    })


    hub.on('Disconnected', message => {
        console.warn('Disconnected from SignalR Hub.', message)
    })


    // with reconnect capability (async/await, not IE11 compatible)
    async function start() {
        try {
            console.log('Attempting reconnect')
            await hub.start()
        } catch (err) {
            console.error(err)
            setTimeout(() => start(), 5000)
        }
    }

    hub.onclose(async () => {
        await start()
    })

    // sample sending of message
    /*
    hub
      .invoke('SendMessageToOthers', {
        action: action,
        data: Object.assign(
          { user: get(store, 'getters.loggedUser.name') },
          message
        )
      })
      .catch(err => console.error(err.toString()))
    */
}