//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import navbar from "@/components/navbar.vue";
import DrawerNav from "@/components/Navigation/DrawerNav";
import loader from "@/components/common/loader";
import { mapActions, mapGetters } from "vuex";
import SelectTime from "@/components/TimeComponents/SelectTime/SelectTime";
import * as Sentry from "@sentry/browser";
export default {
  components: { navbar, DrawerNav, loader, SelectTime },
  fetchOnServer: false,
  middleware: "auth",
  async fetch() {
    try {
      console.log("fetchUser");
      await this.$auth.fetchUser();
      await this.initStorageApp();
    } catch (e) {
      console.error(e);
      await this.$auth.logout();
      await this.$router.push("/login");
      return;
    }
  },
  computed: {
    ...mapGetters("settings", ["settings"]),



    currentTime() {
      if (this.showclavier.currentTime) {
        const _ = this.showclavier.currentTime;
        if (_) {
          const time = _.toString().split(".");
          let minutes = time[1] ?? 0;
          if (minutes.length === 1) {
            minutes = minutes + "0";
          }
          minutes = (parseInt(minutes) * 60) / 100;

          return { hours: time[0] ?? 0, minutes: parseInt(minutes) };
        }
      }
      return { hours: 0, minutes: 0 };
    }
  },
  mounted() {
    this.$EventBus.$on("showclavier", params => {
      this.showclavier = params;
      if (!this.showclavier.currentTime) {
        this.showclavier.currentTime = 0;
      }
      this.showKeyNumeric = true;
    });

    if (typeof document.hidden !== "undefined") {
      this.hidden = "hidden";
      this.visibilityChange = "visibilitychange";
    } else if (typeof document.mozHidden !== "undefined") {
      this.hidden = "mozHidden";
      this.visibilityChange = "mozvisibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
      this.hidden = "msHidden";
      this.visibilityChange = "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
      this.hidden = "webkitHidden";
      this.visibilityChange = "webkitvisibilitychange";
    } else {
      this.hidden = "Page Visibility API not supported.";
    }

    this.$EventBus.$on("createTask", params => {
      this.createTaskParam = params;
      this.showCreateTask = true;
    });
    document.addEventListener(
      this.visibilityChange,
      this.handleVisibilityChange,
      false
    );

    if (
      this.$auth.loggedIn == true &&
      this.$hub._connectionState == "Disconnected"
    ) {
      this.$hub.start().then(() => {
        this.$hub.invoke("register", this.$auth.user.id);
      });
    }
    if (this.$auth.user) {
      Sentry.setContext("user", {
        role: this.$auth.user.role,
        userId: this.$auth.user.id,
        Entreprise: this.$auth.user.entrepriseConnected,
        nom: this.$auth.user.nom,
        prenom: this.$auth.user.prenom
      });
    }
  },
  watch: {
    // "$auth.loggedIn"(to, from) {
    //   console.log(to);
    // },
  },
  data() {
    return {
      taskLabel: "",
      visible: false,
      showCreateTask: false,
      showKeyNumeric: false,
      sidebarWidth: "300px",
      showclavier: {},
      createTaskParam: {},
      hidden: "123",
      visibilityChange: "456"
    };
  },

  methods: {
    handleVisibilityChange() {
      if (
        !document[this.hidden] &&
        this.$hub._connectionState == "Disconnected"
      ) {
        this.$hub.start().then(() => {
          this.$hub.invoke("register", this.$auth.user.id);
        });
      }
    },

    ...mapActions("localStorage", ["initStorageApp"]),
    UpdateTime(obj) {
      this.showclavier.currentTime = obj;
    },
    onCancel() {
      this.showCreateTask = false;
      this.createTaskParam.onCancel();
    },
    onValidate() {
      this.showCreateTask = false;
      this.createTaskParam.onValidate(this.taskLabel);
    },
    handleCancel() {
      this.showKeyNumeric = false;
      this.showclavier = {};
    },
    handleSubmit() {
      this.showclavier.validate(this.showclavier.currentTime);
      this.showKeyNumeric = false;
      this.showclavier = {};
    }
  }
};
