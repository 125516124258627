export default {
  computed: {
    isAdmin() {
      if (!this.utilisateur) return false;
      return (this.role ? this.role : "").toLowerCase() === "administrateur" || this.isSuperAdmin;
    },
    isManager() {
      if (!this.utilisateur) return false;
      return (this.role ? this.role : "").toLowerCase() === "responsable";
    },
    isCommercial() {
      if (!this.utilisateur) return false;
      return (this.role ? this.role : "").toLowerCase() === "commercial";
    },
    isUser() {
      if (!this.utilisateur) return false;
      return this.role.toLowerCase() === "utilisateur";
    },
    isSuperAdmin() {
      if (!this.utilisateur) return false;
      return (this.role ? this.role : "").toLowerCase() === "superadmin";
    },
    utilisateur() {
      return this.$store.$auth.$state.user;
    },
    role() {
      return (this.utilisateur.role && this.utilisateur.refRole) ? this.utilisateur.refRole.label : "";
    }
  },
  methods: {
    isInRole(roles) {
      if (roles && roles.length > 0 && this.utilisateur) {
        return roles.find(d => d.toLowerCase() === this.role.toLowerCase());
      } else {
        return false;
      }
    }
  }
};
